<template>
  <div class="wrapper">
    <!-- div @click="method1" class="ddd">НАЖМИ</div -->
    <!-- start services -->
    <section id="services" class="services section">
      <div class="container-fluid">
        <div class="section-header">
          <div class="line"></div>
          <h2>Stack.</h2>
          <h4>Навыки и умения</h4>
        </div>
        <div class="services-list">
          <div class="services__item-wrapper">
            <div class="services__item">
              <div class="pr-service-box-icon-wrap">
                <i class="icon icon-strategy"></i>
              </div>
              <div class="title-item">
                <h5>Языки программирования</h5>
                JavaScript, TypeScript, Jquery
              </div>
            </div>
          </div>
          <div class="services__item-wrapper">
            <div class="services__item">
              <div class="pr-service-box-icon-wrap">
                <i class="icon icon-browser"></i>
              </div>
              <div class="title-item">
                <h5>Фреймворки и платформы</h5>
                Vue.js, Nuxt.js, Bitrix, Laravel
              </div>
            </div>
          </div>
          <div class="services__item-wrapper">
            <div class="services__item">
              <div class="pr-service-box-icon-wrap">
                <i class="icon icon-presentation"></i>
              </div>
              <div class="title-item">
                <h5>Стилизация и препроцессоры</h5>
                LESS, SASS, SCSS, Stylus
              </div>
            </div>
          </div>
          <div class="services__item-wrapper">
            <div class="services__item">
              <div class="pr-service-box-icon-wrap">
                <i class="icon icon-clipboard2"></i>
              </div>

              <div class="title-item">
                <h5>Инструменты и сборщики</h5>
                Vite,Webpack, Gulp, Docker
              </div>
            </div>
          </div>
          <div class="services__item-wrapper">
            <div class="services__item">
              <div class="pr-service-box-icon-wrap">
                <i class="icon icon-tools"></i>
              </div>

              <div class="title-item">
                <h5>Работа с дизайном</h5>
                Photoshop, Figma, Avocode, Zeplin
              </div>
            </div>
          </div>

          <div class="services__item-wrapper">
            <div class="services__item">
              <div class="pr-service-box-icon-wrap">
                <i class="icon icon-graduation-hat"></i>
              </div>

              <div class="title-item">
                <h5>Методологии и подходы</h5>
                BEM, PUG, Responsive и Adaptive
              </div>
            </div>
          </div>
          <div class="services__item-wrapper">
            <div class="services__item">
              <div class="pr-service-box-icon-wrap">
                <i class="icon icon-target"></i>
              </div>

              <div class="title-item">
                <h5>
                  Знаю разницу между responsive(RWD) и adaptive(ADW) версткой
                </h5>
              </div>
            </div>
          </div>

          <div class="services__item-wrapper">
            <div class="services__item">
              <div class="pr-service-box-icon-wrap">
                <i class="icon icon-lightbulb"></i>
              </div>
              <div class="title-item">
                <h5>Качество кода</h5>
                ESLint, Prettier
              </div>
            </div>
          </div>
          <div class="services__item-wrapper">
            <div class="services__item">
              <div class="pr-service-box-icon-wrap">
                <i class="icon icon-basket"></i>
              </div>
              <div class="title-item">
                <h5>Оптимизация</h5>
                Улучшение производительности и скорости
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- end services -->
    <!-- start portfolio -->
    <ListProjects />
    <!-- end portfolio -->
    <section id="about" class="impact section">
      <div class="container-fluid">
        <div class="section-header">
          <div class="line"></div>
          <h2>Обо мне.</h2>
        </div>
      </div>
      <div class="container-fluid">
        <div class="impact__items">
          <div class="col-about">
            <p>
              Я front-end веб-разработчик с более чем 14-летним опытом в
              создании высокопроизводительных и многофункциональных
              интерактивных веб-сайтов. Моя работа охватывает широкий спектр
              проектов, и я активно сотрудничаю с агентствами по всему миру,
              чтобы предоставлять качественные решения, которые работают на всех
              устройствах и платформах.
            </p>

            <p>
              Мой путь в веб-разработке начался с HTML и CSS, и я помню, как
              искал хаки для IE6. С тех пор я значительно расширил свои навыки и
              сейчас активно использую современные технологии.
            </p>

            <h3>Профессиональные навыки</h3>
            <p>
              <strong>Frontend-разработка:</strong> Глубокое знание Vue.js (4,5
              года) и Nuxt.js (4 года), включая оптимизацию производительности и
              миграцию проектов с Vue 2 на Vue 3. Умею применять Bootstrap и
              другие фреймворки, когда это действительно необходимо.
            </p>

            <p>
              <strong>Оптимизация производительности:</strong> Опыт в
              оптимизации сайтов для достижения высоких показателей в Google
              Page Speed, включая настройку .htaccess и создание Турбо-страниц
              для Яндекса. Знаю, как улучшить сборки Webpack и Vite для
              повышения скорости загрузки.
            </p>

            <p>
              <strong>Разработка компонентов:</strong> Создаю сложные
              компоненты, такие как интерактивные карты, системы авторизации и
              регистрации пользователей, а также универсальные модальные окна.
            </p>

            <p>
              <strong>Микросервисы:</strong> Опыт разработки микросервисов,
              включая Telegram-ботов и другие сложные проекты.
            </p>

            <p>
              <strong>Инструменты сборки:</strong> Уверенное владение Webpack,
              Vite и Gulp, а также управление версиями с помощью Git и Docker.
            </p>

            <p>
              <strong>Типизация:</strong> Хорошее знание TypeScript и его
              применение в разработке Vue-приложений, включая работу с
              Composition API и Pinia для управления состоянием.
            </p>

            <p>
              <strong>UI и верстка:</strong> Профессиональная вёрстка HTML5/CSS3
              с использованием БЭМ, адаптивная верстка и создание кастомных тем
              для приложений, таких как Wiki.js. Опыт работы с графическими
              редакторами Figma, Sketch, Photoshop и Adobe XD для интеграции
              дизайна.
            </p>

            <h3>Технологический стек</h3>
            <p>
              <strong>Frontend:</strong> Vue.js (2 и 3), Nuxt (2 и 3), Vuex,
              Pinia, Axios, Websocket.io, PrimeVue, chart.js
            </p>
            <p><strong>CSS-препроцессоры:</strong> LESS, SASS, SCSS, Stylus</p>
            <p><strong>HTML-препроцессоры:</strong> Pug</p>
            <p><strong>Backend:</strong> Laravel, Bitrix</p>

            <h3>Мой подход</h3>
            <p>
              Я придерживаюсь принципа «прогрессивного улучшения», находя
              творческие решения для расширения возможностей веб-сайтов без
              ущерба для производительности и поддержки браузеров. Открыт к
              сложным и интересным проектам, которые требуют глубокого анализа и
              нестандартных решений.
            </p>

            <h3>Личные интересы</h3>
            <p>
              В свободное время увлекаюсь автоспортом, люблю бег и занимаюсь
              плаванием.
            </p>
          </div>
          <div class="col-infograf">
            <div class="impact__item impact__item--second">
              <h3>Общение</h3>
              <p>Дружелюбно работаю в коллективе</p>
            </div>

            <div class="impact__item impact__item--third">
              <h3>Опыт</h3>
              <p>Активно проявляю инициативу</p>
            </div>
            <div class="impact__item impact__item--four">
              <h3>Креативность</h3>
              <p>
                Предлагаю и внедряю идеи, чтобы выделить продукт среди
                конкурентов.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="section reviews">
      <div class="b-reviews">
        <div class="col-img">
          <div class="rewievs-img">
            <img src="/img/img10.jpg" alt="" />
          </div>
        </div>

        <div class="col-text">
          <div id="review" class="section-header">
            <div class="line"></div>
            <h2>Отзывы клиентов.</h2>
          </div>
          <p>
            "Роман на данный момент, наверное лучший front-end developer из
            всех, с которыми я имел опыт сотрудничать. Он постоянно находится в
            процессе совершенствования своих профессиональных навыков, умеет
            работать быстро и качественно, не боится нестандартных задач." -
            <strong>Michael Ivanov-Shuvalov</strong>, Head of project department
          </p>
          <div class="line-grey"></div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
// import io from 'socket.io-client'

export default {
  data() {
    return {};
  },

  mounted() {
    //  console.log(this.projectList)
    // const io = require("socket.io-client");
    // const socket = io("http://localhost/api", {
    //   withCredentials: true,
    //   transportOptions: {
    //     polling: {
    //       extraHeaders: {
    //         "my-custom-header": "abcd"
    //       }
    //     }
    //   }
    // });
  },
  methods: {
    method() {
      /* Emit events */
      // return new Promise((resolve) => {
      //   this.socket.emit('getMessage', { id: 'abc123' }, (resp) => {
      //     this.messageRxd = resp
      //     resolve()
      //   })
      // })
    },
  },
};
</script>
