<script lang="ts" setup>
import { reactive, ref } from 'vue';
import axios from 'axios';

const currentUser = useAuthUser(); // Получение текущего пользователя

// Состояние компонента с reactive
const state = reactive({
  popupShow: false,
  popupThanks: false,
  errors: [] as string[],
  userPhone: null as string | null,
  userName: null as string | null,
  userMess: null as string | null,
});

// Функция для переключения состояния показа попапа
function writeMe() {
  state.popupShow = !state.popupShow;
  document.body.classList.toggle('lock');
}

// Получение данных с сервера (если нужно)
// async function fetchList() {
//   // const headers = { 'Content-Type': 'text/plain' };
//   // console.log(import.meta.env.VITE_API_URL); // Используйте import.meta.env для доступа к переменным окружения в Vue 3
//   // await axios.get(import.meta.env.VITE_API_URL + '/sendContact', { headers }).then(response => {
//   //   this.list = response.data;
//   // });
// }

// Отправка данных формы
async function submitData() {
  try {
    // Если почта и телефон заполнены, то отправляем форму
    // Проверка на наличие имени и телефона
    if (!state.userName || !state.userPhone) {
      return;
    }
    // if (state.userName && state.userPhone) {
    const formData = new FormData();
    formData.append('userName', state.userName);
    formData.append('userPhone', state.userPhone);
    formData.append('userMess', state.userMess || ''); // Если нет сообщения, отправляем пустую строку

    axios({
      method: 'post',
      url: import.meta.env.VITE_API_URL + '/api/sendContact',
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' },
    })
      .then((response) => {
        console.log('Сообщение отправлено', response);
      })
      .catch((error) => {
        console.error('Ошибка отправки:', error);
      });

    state.popupThanks = true;
    writeMe();
    setTimeout(() => {
      state.popupThanks = false;
    }, 3000);
    // }

    state.errors = [];

    if (!state.userName) {
      state.errors.push('Пожалуйста, введите имя.');
    }
    if (!state.userPhone) {
      state.errors.push('Пожалуйста, введите телефон.');
    }
  } catch (e) {
    console.log('Ошибка отправки:', e);
  }
}

// Вызов метода при монтировании компонента
// fetchList();
</script>

<template>
  <div id="site-wrapper" class="site wide">
    <header class="header">
      <HeaderTop />
      <div id="about" class="container-fluid">
        <div class="header__line"></div>
        <h1 class="animated fadeInDown">
          Frontend <br />
          разработчик <br />
          <span>Создаю уникальные веб-решения для масштабируемых проектов</span>
        </h1>
        <div class="header__items">
          <a href="#portfolio" class="header__item active">
            <i class="icon icon-chart-bars"></i>
            <span class="header__item-content">
              <h3>
                &lt;Реализованные проекты&gt;
                <strong
                  >&lbrace;&zwj;&lbrace; 5000+ проектов
                  &rbrace;&zwj;&rbrace;</strong
                >
              </h3>
              <span class="line"></span>
              <p>
                для крупных компаний, включая: Сбер, РОСАТОМ, MTS,
                Royal&minus;Canin...
              </p>
            </span>
          </a>
          <a href="#services" class="header__item">
            <i class="icon icon-rocket"></i>
            <span class="header__item-content">
              <h3>
                &lt;Опыт&gt;
                <strong
                  >&lbrace;&zwj;&lbrace; 16 лет &rbrace;&zwj;&rbrace;</strong
                >
              </h3>
              <span class="line"></span>
              <p>
                Мой путь в разработке: от верстки и дизайна до SEO оптимизации и
                сложных интерфейсов и архитектуры.
              </p>
            </span>
          </a>
          <a href="#review" class="header__item">
            <i class="icon icon-pie-chart"></i>
            <span class="header__item-content">
              <h3>
                &lt;Работаю на результат&gt;
                <strong
                  >&lbrace;&zwj;&lbrace; 1000+ довольных клиентов
                  &rbrace;&zwj;&rbrace;</strong
                >
              </h3>
              <span class="line"></span>
              <p>
                Только положительный результат, ни одного разочарованного
                клиента
              </p>
            </span>
          </a>
        </div>
      </div>
    </header>
    <MainPage />
    <div id="contact" class="contact section">
      <div class="container-fluid">
        <h2>Хотите связаться?</h2>
        <div class="button" @click="writeMe">
          <span>Написать</span>
        </div>
      </div>
    </div>

    <footer class="footer">
      <div class="container-fluid">
        <div class="copy">
          2024 <span>Just Works</span>, All rights reserved.
        </div>
        <div>front-end developer, County Russia, city Chelyabinsk</div>
      </div>
    </footer>
    <ChatComp />
    <div v-show="state.popupShow" class="popup">
      <form class="form-content">
        <div class="popup-header">
          <h2>Хотите связаться?</h2>
          <svg width="14" height="14" viewBox="0 0 14 14" @click="writeMe">
            <line
              fill="none"
              stroke="#000"
              stroke-width="1.1"
              x1="1"
              y1="1"
              x2="13"
              y2="13"
            />
            <line
              fill="none"
              stroke="#000"
              stroke-width="1.1"
              x1="13"
              y1="1"
              x2="1"
              y2="13"
            />
          </svg>
        </div>

        <div class="form-text">Давайте вместе сделаем что-нибудь классное</div>
        <input v-model="state.userName" type="text" placeholder="Name" />
        <input
          v-model="state.userPhone"
          type="text"
          placeholder="Telegram, Email"
        />
        <textarea
          v-model="state.userMess"
          cols="30"
          rows="10"
          placeholder="Messege"
        ></textarea>
        <input
          type="submit"
          value="Send"
          :disabled="!state.userName || !state.userPhone"
          @click.prevent="submitData"
        />
      </form>
    </div>

    <div :class="{ active: state.popupThanks }" class="popup-thanks">
      <div class="text">
        <strong>Спасибо.</strong><br />
        Ваше сообщение отправленно.<br />
        Хорошего Вам дня.
      </div>
    </div>
  </div>
</template>
