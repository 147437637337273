<script setup>
import { ref, onMounted, onBeforeUnmount } from 'vue';
import { io } from 'socket.io-client';

// const socket = io('/socket.io/', {
//   path: '/socket.io' // Укажите путь для явного соответствия
// }); // Используем URL вашего сервера
const socket = io('https://go-vue.ru/', {
  reconnectionAttempts: 10, // Количество попыток переподключения
  reconnectionDelay: 2000, // Задержка между переподключениями в миллисекундах
  timeout: 20000, // Тайм-аут соединения
  pingInterval: 30000, // Интервал между пингами
  pingTimeout: 120000, // Тайм-аут ожидания ответа на пинг
}); // Используем URL вашего сервера
// const socket = io('http://localhost:3006'); // Используем URL вашего сервера

const isConnected = ref(false);
const transport = ref('N/A');
const chatInput = ref('');
const chatHide = ref(true);
const messages = ref([]);

// Функция для установки статуса соединения
function onConnect() {
  isConnected.value = true;
  transport.value = socket.io.engine.transport.name;

  // Обновляем транспорт при изменении соединения
  socket.io.engine.on('upgrade', (newTransport) => {
    transport.value = newTransport.name;
  });
}

function onDisconnect() {
  isConnected.value = false;
  transport.value = 'N/A';
}

socket.on('connect', onConnect);
socket.on('disconnect', onDisconnect);

// Управление состоянием чата
function toggleChat() {
  chatHide.value = !chatHide.value;
}

// Функция отправки сообщения
function sendMessage() {
  if (chatInput.value.trim()) {
    const message = { from: socket.id, text: chatInput.value }; // Используем socket.id как идентификатор
    socket.emit('message', message); // Отправка сообщения на сервер
    messages.value.push({ ...message, source: 'local' }); // Добавляем сообщение в локальный список
    chatInput.value = ''; // Очищаем поле ввода
  }
}

// Настройка получения сообщений от сервера
onMounted(() => {
  socket.on('message', (msg) => {
    console.log('Получено сообщение от сервера:', msg);
    // Проверяем, что сообщение не от нас
    if (msg.from !== socket.id) {
      messages.value.push(msg); // Добавляем только сообщения других пользователей
    }
  });
});

// Удаляем слушатели событий при размонтировании компонента
onBeforeUnmount(() => {
  socket.off('connect', onConnect);
  socket.off('disconnect', onDisconnect);
  socket.off('message');
});
</script>
<template>
  <div class="chat" :class="{ chat__hidden: chatHide }">
    <div class="chat-header">
      <div class="chat-title">
        Онлайн чат
        <span class="status" :class="isConnected ? 'green' : 'red'"></span>
      </div>
      <button class="chat-close" type="button" @click="toggleChat">
        <svg
          width="16"
          height="10"
          viewBox="0 0 16 10"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10.707 0L9.293 1.414L11.586 3.707L0 3.707V5.707L11.586 5.707L9.293 8L10.707 9.414L15.414 4.707L10.707 0Z"
          ></path>
        </svg>
      </button>
    </div>
    <!-- end chat-header -->

    <div class="chat-cont">
      <!-- p Status: {{ isConnected ? "connected" : "disconnected" }} -->
      <!-- p Transport: {{ transport }} -->
      <!-- p {{ messages }} -->
      <ul v-if="messages.length > 0" class="chat-list">
        <li
          v-for="msg in messages"
          :key="msg.text"
          class="chat-item"
          :class="{ 'chat-item__me': msg.from !== socket.id }"
        >
          <div class="chat-right">
            <div class="chat-item-name">{{ msg.from }}</div>
            <div class="chat-item-time">{{ msg.source }} 23:49</div>
            <div class="chat-item-desc">{{ msg.text }}</div>
          </div>
        </li>
      </ul>
    </div>
    <!-- end chat-cont -->

    <div class="chat-bottom">
      <div class="chat-form">
        <div class="chat-form__inner">
          <label class="field-wrapper" for="chat-input">
            <input
              id="chat-input"
              v-model="chatInput"
              class="field-textarea"
              type="text"
              placeholder="Введите сообщение"
              autocomplete="off"
              @keydown.enter="sendMessage"
            />
          </label>
          <button
            class="btn --pink"
            :class="{ '--disabled': chatInput.length == 0 }"
            type="button"
            :disabled="chatInput.length == 0"
            @click="sendMessage"
          >
            <svg
              width="26"
              height="25"
              viewBox="0 0 26 25"
              fill="#fff"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.22546 18.569V23.459C9.22546 23.8006 9.44476 24.1032 9.7695 24.2107C9.85068 24.2371 9.93397 24.2497 10.0162 24.2497C10.2629 24.2497 10.5012 24.1337 10.653 23.9271L13.5134 20.0345L9.22546 18.569Z"
              ></path>
              <path
                d="M24.9719 0.146646C24.7294 -0.0252105 24.411 -0.0484058 24.1474 0.0897116L0.424942 12.4781C0.14449 12.6247 -0.0210397 12.9241 0.00215556 13.2393C0.0264052 13.5556 0.236217 13.8255 0.534593 13.9278L7.12944 16.182L21.1742 4.17314L10.3061 17.2669L21.3587 21.0445C21.4409 21.072 21.5274 21.0867 21.6138 21.0867C21.7572 21.0867 21.8996 21.0477 22.025 20.9718C22.2254 20.8495 22.3614 20.6439 22.3962 20.413L25.2956 0.907873C25.3388 0.61266 25.2144 0.319556 24.9719 0.146646Z"
              ></path>
            </svg>
          </button>
        </div>
        <!-- end chat-form -->
      </div>
      <!-- end chat-bottom -->
    </div>
  </div>
</template>

<style lang="sass">

.chat
  display: flex
  flex-direction: column
  width: 100%
  background: rgba(0,0,0, .9)
  border-radius: .3rem .3rem 0 0
  border: 1px solid #e9204f
  position: fixed
  bottom: 0
  right: 0
  height: 500px
  max-width: 360px
  transition: all 0.3s ease
  z-index: 1
  @media(max-width: 1500px)
    max-width: 320px
  @media(max-width: 360px)
    max-width: 90%
  &__hidden
    background: radial-gradient(50% 50% at 50% 50%, #2F3439 0%, #1F242B 100%)
    border: 1px solid #272C32
    border-radius: .5rem .5rem 0 0
    position: fixed
    top: 50%
    right: -371px
    color: #fff
    transition: all 0.3s ease
    text-transform: uppercase
    font-size: 12px
    z-index: 10
    padding: 0 2rem 2rem
    letter-spacing: .1rem
    transform: rotate(-90deg)
    cursor: pointer
    margin-top: -350px
    @media(max-width: 1500px)
      margin-top: -330px
      right: -360px
    @media(max-width: 360px)
      right: -36rem
    &:hover
      @media(min-width: 769px)
        right: -360px
    .chat
      &-close
        transform: rotate(-90deg)
        top: .7rem
      &-header
        padding-top: 13px
  &-hide
    background: radial-gradient(50% 50% at 50% 50%, #2F3439 0%, #1F242B 100%)
    border: 1px solid #272C32
    border-radius: .5rem .5rem 0 0
    position: fixed
    top: 50%
    right: -165px
    color: #fff
    transition: all 0.3s ease
    text-transform: uppercase
    font-size: 12px
    z-index: 10
    padding: 1.3rem 2rem 2rem
    letter-spacing: .1rem
    transform: rotate(-90deg)
    cursor: pointer
    @media(max-width: 1500px)
    &:hover
      background: #2F3439
    svg
      fill: #3B4042
      position: relative
      transform: rotate(-90deg)
      left: -1rem
  &-r
    position: relative
    background: #111217
    border: 1px solid #14161B
    margin: -2rem 0 0
    @media(max-width: 1600px)
      position: fixed
      z-index: 23
      top: 0
      right: -35rem
      margin: 0
      bottom: 0
  &-header
    padding: 2rem 2rem 2rem 50px
    display: flex
    justify-content: space-between
    align-items: center
    position: relative
    @media(max-width: 1600px)
      // padding-left: 2rem
      padding-right: 5rem
      padding-bottom: 0
      .chat-free
        display: none
    
    @media(max-width: 767px)
      padding-left: 4rem
      padding-right: 2rem
      .chat-free
        display: block
        position: absolute
        top: 0
        left: 0
        right: 0
        margin: 0
    @media(max-width: 620px)
      padding-left: 5rem
      
  &-title
    color: #fff
    font-size: 18px
    text-transform: uppercase

    .status
      background: #00EC9D
      padding-top: .5rem
      width: .5rem
      height: .5rem
      display: inline-block
      vertical-align: top
      border-radius: 50%
      margin-left: .5rem

  .specification
    color: #3B4042
    font-size: 1.2rem
    display: inline-flex
    align-items: center
    text-transform: uppercase
    svg
      margin-right: 1rem
      fill: #3B4042
      width: 2rem
      height: 1.9rem
    &:hover
      color: #fff
      svg
        fill: #fff !important
  &-close
    position: absolute
    top: 1.5rem
    left: -1rem
    width: 40px
    height: 40px
    background: #f43461
    border-radius: 5px
    align-items: center
    display: inline-flex
    justify-content: center
    border: 1px solid #e9204f
    @media(max-width: 767px)
      display: inline-flex
      // top: 6.8rem
      left: -1rem


    svg
      width: 1.5rem
      height: .9rem
      fill: #fff
    &:hover
      background: #2A2E37
      svg
        fill: #fff
  &-close-close
    display: none
    position: absolute
    top: 2rem
    right: 2rem
    @media(max-width: 1600px)
      display: block
    svg
      width: 1.4rem
      height: 1.4rem
  &-cont
    flex: 1 1 auto
    overflow: auto
    max-height: calc(100vh - 29.5rem)
    @media(max-width: 1600px)
      max-height: calc(100vh - 12.5rem)
      margin-top: 15px
  &-item
    display: flex
    width: calc(100% - 5rem)
    max-width: 32rem
    padding: 15px 20px
    border-radius: 1rem
    background: #f43461
    color: #fff
    margin-bottom: 1rem
    font-size: 12px
    @media(max-width: 767px)
      max-width: 100%
    &__me
      margin-left: auto
      background: rgba(255,255,255, .9)
      color: #101010

    &.offline
      .chat
        &-item
          &-photo
            &:before
              background: linear-gradient(180deg, #d70f0f 0%, #b31616 100%)
              box-shadow: 0 0 10px rgba(0, 186, 124, 0.2)

    &-photo
      min-width: 4.8rem
      max-width: 4.8rem
      display: block
      position: relative
      & + .chat-right
        margin-left: 1rem
      &:before
        content: ""
        width: .8rem
        height: .8rem
        border-radius: 50%
        background: linear-gradient(180deg, #00FFAA 0%, #00C885 100%)
        box-shadow: 0 0 10px rgba(0, 186, 124, 0.2)
        position: absolute
        z-index: 1
        bottom: .3rem
        right: .3rem
      &:after
        width: 2rem
        height: 2rem
        border-radius: 10px 0 0 0
        content: ""
        background: #111217
        position: absolute
        bottom: -.3rem
        right: -.3rem

    &-header
      display: flex
      justify-content: space-between
      padding-bottom: .5rem
      font-size: 1.2rem
      width: 100%
    &-name
      color: #fff
      text-transform: uppercase
      overflow: hidden
      text-overflow: ellipsis
      white-space: nowrap
      max-width: calc(100% - 6rem)
    &-time
      color: #3B4042
    &-desc
      line-height: 1.5
      font-size: 16px

  &-right
    flex: 1 1 auto
    max-width: 100%
  // border-bottom: 1px solid #27292E
  
  &-bottom
    padding-top: 2rem
    padding-bottom: 2rem

  &-form__inner
    position: relative
    display: -webkit-box
    display: flex
    -webkit-box-pack: justify
    justify-content: space-between

  &-form
    position: relative
    padding: 0 0 0 1.5rem
    .field-wrapper
      width: calc(100% - 5rem)
      display: block
      margin-right: 4.5rem

      .field-textarea
        font-size: 1.4rem
        font-weight: 500
        background: #21252C
        border-radius: .5rem
        color: #fff
        width: 100%
        padding: 1rem
        border: none
        height: 4rem

        &:-webkit-input-placeholder,
        &::-webkit-input-placeholder,
        &::-moz-placeholder,
        &:-moz-placeholder,
        &::-ms-input-placeholder,
        &:-ms-input-placeholder,
        &::placeholder,
        &:placeholder
          color: #fff
          opacity: 1

  &-form-stats
    position: absolute
    top: 2rem
    right: 1.5rem
    width: 4.8rem
    text-align: center
    color: rgba(235, 239, 253, 0.2)
    font-size: 1.2rem

  .btn
    height: 4rem
    width: 4rem
    cursor: pointer
    border-radius: .5rem
    position: absolute
    padding: 0
    bottom: 0
    right: 1rem
    &:hover
      svg
        fill: #fff

    svg
      position: absolute
      cursor: pointer
      width: 2.5rem
      height: 2.1rem
      left: calc(50% - 1.2rem)
      top: calc(50% - 1rem)
      fill: #fff
    &.--pink
      background: #e9204f
      color: #fff
    &.--disabled
      background: #bcbcbc
      cursor: default
      svg
        cursor: default
        fill: #fff
  &-free
    background: linear-gradient(90deg, #FF4A00 0%, #FF6800 100%)
    bottom: 0
    left: 0
    width: 100%
    height: 6rem
    position: absolute
    z-index: 5
    @media(max-width: 767px)
      position: relative
      top: 0
      margin: 0 -2rem
      width: auto
    &__cont
      position: relative
      display: flex
      flex-direction: column
      justify-content: center
      z-index: 1
      // background: url(../img/surprize.png) 1.5rem 50% no-repeat
      background-size: 4.7rem auto
      padding-left: 7rem
      height: 6rem
    &__title
      color: #FFD66D
      font-size: 1.2rem
      text-transform: uppercase
      padding-bottom: .2rem
      padding-top: 2 rem
    &__timer
      text-shadow: 0px 0px 12px rgba(255, 255, 255, 0.7)
      font-size: 1.7rem
      color: #fff
    &__arrow
      width: 4rem
      height: 4rem
      top: calc(50% - 2rem)
      position: absolute
      right: 2rem
      border-radius: .5rem
      background: rgba(255,179,109, .4)
      &:hover
        background: rgba(255,179,109, 1)
      svg
        fill: #fff
        width: 1.3rem
        height: .8rem
        @media(max-width: 1600px)
          transform: rotate(-90deg)
        @media(max-width: 767px)
          transform: rotate(0)
#noTrespassingOuterBarG
  height: 6rem
  width: 100%
  overflow: hidden
  // background-color: #fe6910
  background-color: linear-gradient(90deg, #FF4A00 0%, #FF6800 100%)
  position: absolute
  top: 0
  left: 0

.noTrespassingBarLineG
  background-color: #fe6910
  float: left
  width: 2rem
  height: 24rem
  margin-right: 4rem
  margin-top: -5rem
  transform: rotate(45deg)


.noTrespassingAnimationG
  width: 150%
  animation-name: noTrespassingAnimationG
  animation-duration: 1.5s
  animation-iteration-count: infinite
  animation-timing-function: linear


@keyframes noTrespassingAnimationG
  0%
    margin-left: -7rem

  100%
    margin-left: -13.1rem

.float-chat__open
  .chat-r
    right: 0
    @media(max-width: 767px)
      width: 100%
      max-width: 100%
    &:after
      @media(max-width: 1600px)
        content: ''
        position: fixed
        top: 0
        right: 0
        left: 0
        height: 100%
        z-index: -1
        background: #191B21
        opacity: .8
      @media(max-width: 767px)
        display: none
.free-skin__open
  .free-skin
    &-info
      opacity: 1
      max-height: calc(100vh - 22.5rem)
      right: 0
      z-index: 11
      @media(max-width: 1600px)
        top: auto
        bottom: 0
        max-height: 100vh
        right: auto
        &:after
          content: ''
          position: fixed
          top: 0
          right: 0
          left: 0
          height: 100%
          z-index: -1
          background: #191B21
          opacity: .8
          @media(max-width: 767px)
            display: none
      @media(max-width: 767px)
        left: 0
        z-index: 24
        top: 6rem
        width: 100%
  .chat-free__arrow svg
    transform: rotate(90deg)
    @media(max-width: 767px)
      transform: rotate(180deg)
.free-skin
  &-info
    // background: #111217 url(../img/bg-prize.png) 50% 0 no-repeat
    background-size: contain
    padding-top: 2.7rem
    overflow: hidden
    opacity: 0
    position: fixed
    transition: all 0.3s ease
    top: 23rem
    right: -35rem
    width: 32rem
    z-index: 2
    bottom: 0
    @media(max-width: 1600px)
      left: 33rem
      bottom: 100vh
      max-height: 90vh
      height: 100vh
      top: auto
      @media(max-width: 767px)
        top: 6rem
        bottom: 0
        left: -40rem
        max-height: 100vh
  &-close
    display: none
    z-index: 2
    @media(max-width: 1600px)
      display: block
      position: absolute
      top: 2rem
      right: 2rem
      svg
        width: 1.4rem
        height: 1.4rem
    @media(max-width: 767px)
      display: none
  &-content
    overflow: auto
    position: absolute
    top: 0
    left: 0
    bottom: 0
    width: 100%
  &-prize
    padding-bottom: 4rem
  &-img
    //background: url(../img/prizee.png) 50% 0 no-repeat
    background-size: contain
    text-align: center
    padding: 0
    margin: 0 auto
    position: relative
    &:after
      content: ""
      box-shadow: 0 0 7rem 7rem rgba(214,163,82, .5)
      position: absolute
      top: 50%
      left: 50%
    img
      max-width: 50%
      position: relative
      z-index: 1

  &-name
    font-size: 1.6rem
    text-align: center
    color: #fff
    padding-bottom: .5rem
  &-case
    font-size: 1.2rem
    text-align: center
    color: #747A7C
  &-text
    text-align: center
    h3
      font-size: 1.4rem
      color: #fff
      text-align: center
      text-transform: uppercase
      margin: 0
      padding: 0 0 1rem
    .text
      color: #747A7C
      font-size: 1.4rem
      line-height: 1.5
      padding: 0 2rem 2.5rem
    ul.list-ico
      padding: 0
      margin: 0
      list-style: none
      font-size: 1.4rem
      color: #fff
      li
        margin-bottom: 2rem
      img
        width: 1.8rem
        height: 1.8rem
        margin: 0 1rem 0 0
        vertical-align: middle
.users-win
  border-top: 1px solid #212126
  padding-top: 3rem
  .title
    text-align: center
    color: #747A7C
    font-size: 12px
    text-transform: uppercase
    padding-bottom: .8rem
  .user
    display: flex
    padding: 2rem
    justify-content: space-between
    align-items: center
  .ava
    width: 4.8rem
    max-width: 4.8rem
    height: 4.8rem
    position: relative
    margin-right: 1rem
    span
      display: block
      overflow: hidden
      width: 4.8rem
      height: 4.8rem
      border-radius: 50%
    &:after
      content: ""
      width: 6rem
      height: 6rem
      //background: url(../img/user-winer.png) 50% 50% no-repeat
      background-size: contain
      position: absolute
      top: -.1rem
      left: -.6rem
  .right
    flex: 1 1 auto
    padding: 0 1rem
    font-size: 1.4rem
  .name
    color: #fff
    padding-bottom: .5 rem
  .time
    color: #747A7C
    svg
      width: 1.9rem
      height: 1.9rem
      margin-right: .5rem
      vertical-align: middle
  .inventar-item
    background-color: unset !important
    background-size: 80%
    padding: 0
    width: 7rem
    max-width: 7rem
    height: 7rem
    &:after
      box-shadow: 0 0 2rem 2rem rgba(24, 75, 255, 0.4)
    span
      position: relative
      z-index: 1
</style>
