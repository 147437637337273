<template>
  <!-- start portfolio -->
  <section id="portfolio" class="portfolio section">
    <div class="container-fluid">
      <div class="section-header">
        <div class="line"></div>
        <h2>
          Портфолио.
        </h2>
        <h4>НЕБОЛЬШАЯ КОЛЛЕКЦИЯ МОИХ РАБОТ</h4>
      </div>
    </div>
    <div class="portfolio-list">
      <div
        v-for="item in projectList"
        :key="item.id"
        class="portfolio-item-wrapper"
      >

          <NuxtLink class="portfolio__item" :to="'/project/' + item.id">
              <img :src="`${item.img}`" :title="item.name" :alt="item.name" />
          </NuxtLink>
        
      </div>
    </div>
  </section>
  <!-- end portfolio -->
</template>

<script>

export default {
  data () {
    return {
      projectList: [
        {
          "id": 0,
          "name": "Ggwin",
          "desc": "responsive, rem, gulp, PUG, SASS",
          "img": "/img/port/ggwin.png",
          "link": "https://p-store.ru/ruletka2/inventar-slider.html",
          "github": ""
        },
        {
          "id": "1",
          "name": "LilySammer",
          "desc": "vue.js, vuex, webpack, PUG, SASS",
          "img": "/img/port/lily.png",
          "link": "",
          "github": "https://github.com/ItJustAlance/lily-kviz"
        },
        {
          "id": 2,
          "name": "World Wentures",
          "desc": "animations",
          "img": "/img/port/trans.png",
          "link": "https://p-store.ru/transplanetary/templates/sectors.html",
          "github": ""
          
        },
        {
          "id": 3,
          "name": "mi-74.ru",
          "desc": "design, adaptive, front-end, back-end, seo optimize, sheme.org(article, shop, contacts), the site is optimized and gives out 97 points in 'google page speed'",
          "img": "/img/port/mi-74.png",
          "link": "https://mi-74.ru",
          "github": ""
        },
        {
          "id": 4,
          "name": "lgcity.ru",
          "desc": "adaptive, gulp, PUG, SASS, skeleton.js",
          "img": "/img/port/lgcity.png",
          "link": "https://lgcity.ru",
          "github": ""
        },
        {
          "id": 5,
          "name": "ford-service",
          "desc": "Адаптивная верстка с калькулятором расчета стоимости ТО и взаимосвязь с бэком",
          "img": "/img/port/ford.png",
          "link": "https://p-store.ru/anna-ford/"
        }
      ]
      
      
      
    }
  },
  mounted () {
    // console.log(this.projectList)
  }
}
</script>
